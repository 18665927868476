import 'bootstrap';
import { Modal } from 'bootstrap';
import './bootstrap.js';
import 'select2';
import 'jquery-sticky';
import { MaskInput } from "maska";

require('./styles/app.scss');
require('select2/dist/css/select2.min.css');

$(".main-nav").sticky({ topSpacing:0 });
$('.select2').select2();

$('[data-toggle="tooltip"]').tooltip();
new MaskInput("[data-maska]");

// $('#basicFormModal').on('show.bs.modal', function() {
//     $.ajax({
//         url: '/usoc/reward/1',
//         type: 'GET',
//         success: function(data) {
//             $('#modal-content').html(data);
//         }
//     });
// });

$('#userProductModal').on('show.bs.modal', function() {
    $.ajax({
        url: '/console/admin/user/product/1',
        type: 'GET',
        success: function(data) {
            $('#modal-content').html(data);
        }
    });
});


$(document).ready(function() {
    // Hide option from results when selected
    const selectElement = $('#usoc_campaign_dupCheckCampaigns');
    function filterSelectedOptions(option) {
        // Return the default text for placeholders
        if (!option.id) return option.text;

        // Get selected values as an array
        const selectedValues = selectElement.val() || [];

        // Hide already selected options
        return selectedValues.includes(option.id) ? null : option.text;
    }

    selectElement.select2({
        templateResult: filterSelectedOptions
    });
});

